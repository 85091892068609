.foto__container {
    display:flex;
    align-items:center;
    justify-content:center;
    background:#fff;

    .foto__left,
    .foto__right {
        width:220px;
        height:315px;
        background:#fff;
        border-top-width:6px;
        border-bottom-width:6px;        
        border-style:solid;
        border-color:#fff;
        background-size:cover;
        background-position:center;
        position:relative;
    }

    .foto__left {
        border-left-width:6px;
        border-right-width:3px;
        border-top-left-radius:10px;
        border-bottom-left-radius:10px;
    }

    .foto__right {
        border-left-width:3px;
        border-right-width:6px;
        border-top-right-radius:10px;
        border-bottom-right-radius:10px;        
    }

    .foto__right_container {
        .foto__rate {
            position:absolute;
            bottom:-10px;
            right:0;
            z-index:2;
            display:flex;
            gap:6px;

            .foto__icon {
                background:#fff;
                width:40px;
                height:40px;
                border-radius:50%;
                display:flex;
                align-items:center;
                justify-content:center;
                text-align:center;
                box-shadow:0px 10px 15px -5px rgb(0 0 0 / 31%);
                cursor:pointer;

                >div {
                    &:before {
                        display:none;
                    }
                }
            }

            svg {
                font-size:20px;
            }
        }
    }
}

@media screen and (max-width:500px){
    .foto__container {
        width:100%;

        .foto__left_container,
        .foto__right_container {
            width:50%;
            height:70vw;
        }

        .foto__left,
        .foto__right{
            width:100%;
            height:100%;
        }
    }
}