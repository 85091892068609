.titleActions {
    display:flex;
    align-items:center;
    gap:0 7px;
}

@media print {
    .titleActions {
        display:none;
    }
}

@media screen and (max-width:500px){
    .titleActions {
        gap:0 10px;
    }
}