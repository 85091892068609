.swiper-slide {
    width:auto;
}

.swiper-button-next,
.swiper-button-prev {
    background:#757575;
    position:fixed;
    width:46px;
    top:calc(50% - (23px - 62px));
    transition:opacity ease .5s;
    height:46px;
    border-radius:0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background:#656565;
}

.swiper-button-prev {
    left:50px;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
}

.swiper-button-next {
    right:0;
    border-top-left-radius:6px;
    border-bottom-left-radius:6px;
}

.swiper-button-prev:before,
.swiper-button-next:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #fff;
    font-size:20px;
}


.swiper-button-prev:after,
.swiper-button-next:after {
    color: #fff;
    font-size: 18px;
    font-weight: 900;
}

.swiper-button-disabled {
    opacity:.1!important;
}

.swiper-pagination {
    position:relative!important;
    margin-top:25px;
}

.swiper-pagination-bullet {
    width: 10px!important;
    height: 10px!important;
    margin: 0 4px;
    opacity:.1!important;
}

.swiper-pagination-bullet-active {
    background: #97a3b3!important;
    opacity:1!important;
}

@media screen and (max-width:500px){
    .swiper-button-prev,
    .swiper-button-next {
        display:none;
    }
}