.titleFilter {
    display:flex;
    align-items:center;
    margin-left:15px;
    flex:1;

    >div {
        margin-right:7px;
    }

    button{
        margin-top:0;
    }
}

@media screen and (max-width:500px){
    .titleFilter {
        flex-wrap:wrap;

        * {
            font-size:14px!important;
        }

        >div {
            flex:0 0 calc(50% - 5px);
            margin-bottom:12px;
        }

        >div:nth-child(even) {
            margin-right:0;
        }

        button {
            flex:1;
            padding:12px 18px;
        }

        [data-input] {
            padding-top:12px;
            padding-bottom:12px;
        }
    }
}

@media print {
    .titleFilter {
        div {
            padding:0!important;
            font-size:1.4rem!important;
            font-weight:300;
            background:transparent;

            svg {
                display:none;
            }
        }
    }
}