.box__input {
    background:#fff;
    border:1px solid #e5e9ec;
    border-radius:4px;
    padding:8px 12px;    
    display:flex;
    align-items:center;
    justify-content:space-between;
    transition:background ease .3s;

    &.box__input_focus {
        background:#f4f5f7;
    }

    input {
        border:none;
        outline:none;
        width:100%;
        text-align:left;
        padding:1px 2px;
        font-size:13px;
        background:transparent;
    }

    .input__period {
        display:flex;
        align-items:center;

        label {
            padding-right:4px;
        }

        >div {
            display:flex;
            align-items:center;
        }
    }

    &.checkbox {
        border:none;
        display:flex;
        justify-content:start;

        input {
            width:auto;
            margin-right:8px;
            -webkit-appearance: none;
            appearance: none;
            background-color:#fff;
            font: inherit;
            color: #c2c6cb;
            width: 1.15em;
            height: 1.15em;
            border: 0.15em solid currentColor;
            border-radius: 0.15em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            cursor:pointer;

            &:before {
                content: "";
                width: 0.65em;
                height: 0.65em;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: bottom left;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em #fff;
                background-color: #fff;                
            }

            &:checked {
                background:#25aa41;
                border-color:#25aa41;
            }

            &:checked:before {
                transform: scale(1);
            }

            &:disabled {
                color: #25aa41;
                cursor: not-allowed;
            }
        }

        label {
            cursor:pointer;
        }
    }

    label {
        padding-right:8px;
        font-weight:400;
        font-size:13px;
        color:#aaa;
        white-space:nowrap;
    }

    svg {
        // margin-left:8px;
    }
}

@media screen and (min-width:500px){
    .box__input{
        .input__period {
            input {
                width:85px;
            }
        }
    }
}

@media screen and (max-width:500px){
    .box__input{
        svg {
            font-size:16px!important;
        }
    }
}