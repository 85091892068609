.button {
    background:#fff;
    outline:none;
    border:1px solid #ddd;
    border-radius:4px;
    padding:8px 18px;
    font-size:14px;
    margin-top:10px;

    &:hover {
        background:#eee;
    }
    
    &.button__default {
        background:#0aa699;
        border-color:#0a968a;
        color:#fff;

        &:hover {
            background:#098f84;
        }
    }

    &.button__white {
        background:#fff;
        border-color:#ddd;

        &:hover {
            background:#eee;
        }
    }

    &.button__disabled {
        pointer-events:none;
        opacity:.5;
    }
}
