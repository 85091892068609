.card {
    background:#fff;
    max-width:100%;
    min-height:94px;
    border-radius:4px;
    box-shadow:0 2px 4px -2px rgb(0 0 0 / 50%);
    margin-bottom:17px;  
    overflow:hidden;

    .card__header {
        padding:10px 15px;
        border-left:3px solid #0086c9;

        &.bg__blue {
            background:#039be5;
            border:#0086c9;
    
            * {
                color:#fff;
            }
        }
    
        &.bg__orange {
            background:#ffb300;
            border-color:#db9b06;
    
            * {
                color:#fff;
            }
        }
    
        &.bg__red {
            background:#e53935;
            border-color:#be2725;
    
            * {
                color:#fff;
            }
        }
    
        &.border__blue {
            border-color:#0086c9;
        }
    
        &.border__orange {
            border-color:#db9b06;
        }
    
        &.border__red {
            border-color:#0086c9;
        }

        .title {
            font-size:20px;
            margin-bottom:3px;
            font-weight:300;
        }

        .subtitle {
            font-size:13px;
            font-weight:300;
        }

        .obs1, .obs2, .obs3 {
            line-height:1.2;
            font-size:13px;
            font-weight:300;
        }

        svg {
            cursor:pointer;
        }

        .left {
            svg {
                font-size:14px;
            }   

            >div {
                margin-right:8px;
            }
        }
        
        .right {
            svg {
                font-size:17px;
            }   
        }
    }

    .card__body {
        box-shadow: 0 30px 20px -35px inset rgb(0 0 0 / 10%);
        
        >div {
            padding:20px 15px;

            form {
                padding:0;
                box-shadow:none;
            }
        }
    }
}

.card__empty {
    background:#d9dde0;
    color:#999;
    display:flex;
    align-items:center;
    justify-content:center;
    text-align:center;
    box-shadow:none;
}

.card__small {
    min-height:71px;
}

@media screen and (max-width:500px){
    .card{
        width:100%!important;
        
        .card__header{
            .left {
                svg {
                    font-size:18px;
                }   
            }
            
            .right {
                svg {
                    font-size:24px;
                }   
            }
        }
    }
}