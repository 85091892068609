.tableContainer {
    background:#fff;
    border:1px solid #eee;
    border-radius:6px;
    overflow:auto;
    box-shadow:0 10px 20px -10px rgb(0 0 0 / 10%);

    .text__limit td {
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }

    .text__limit_10 td {
        max-width:10ch;
    }

    .text__limit_20 td {
        max-width:20ch;
    }

    .text__limit_30 td {
        max-width:30ch;
    }

    .text__limit_40 td {
        max-width:40ch;
    }

    .text__limit_50 td {
        max-width:50ch;
    }

    .text__limit_60 td {
        max-width:60ch;
    }

    .text__limit_70 td {
        max-width:70ch;
    }

    .text__limit_80 td {
        max-width:80ch;
    }

    .text__limit_90 td {
        max-width:90ch;
    }

    .text__limit_100 td {
        max-width:100ch;
    }
}

.tableContainerFixed {        
    max-height:calc(100vh - 176px);
}

@media print {
    .tableContainer {
        box-shadow:none;
        border:none;
        width:100%;
    }
}