.title {
    font-weight:300;
    margin:0 0 15px;
    font-size:1.4rem;
    color:#4c505b;
    position:relative;
    z-index:5;
    display:flex;
    justify-content:space-between;
    align-items:center;

    svg {
        font-size:17px;
        cursor:pointer;
    }

    [data-select] {
        width:auto;
        padding:0 7px 0;
    }
}

@media screen and (max-width:500px){
    .title {
        [data-select] {
            padding-top:3px;
            padding-bottom:3px;
        }
    }    
}