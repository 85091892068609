.box__select {
    background:#fff;
    border:1px solid #e5e9ec;
    border-radius:4px;     
    display:flex;
    align-items:center;
    justify-content:space-between;
    transition:background ease .3s;
    padding:1px 12px;
    width:100%;
    // position:relative;
    // z-index:2;

    >div {
        width:100%;
    }

    &.box__select_focus {
        background:#f4f5f7;
    }

    label {
        padding-right:8px;
        font-weight:400;
        font-size:13px;
        color:#aaa;
        white-space:nowrap;
    }
}