header {
    position:sticky;
    top:0;
    z-index:9;
    border-bottom:1px solid #ddd;
}

.navbar {
    position:relative;
    top:0;
    width:100%;
    background:#fff;
    display:flex;
    align-items:center;

    ul {
        list-style:none;
        padding:0;
        margin:0;
        display:flex;
        flex:1;

        li {
            position:relative;
            z-index:2;
        }
    }
}

.logo {
    position:relative;
    padding:0 20px;

    &:after {
        content:'';
        position:absolute;
        z-index:1;
        top:0;
        right:-1px;
        width:1px;
        height:100%;
        background:#ddd;
    }
    
    img {
        height:30px;
    }
}

@media screen and (max-width:500px){
    .navbar {    
        justify-content: space-between;

        ul {
            display:none;
        }
    }
}

@media print{
    header {
        display:none;
    }
}