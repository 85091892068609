.form {
    background:#fff;
    padding:20px;
    border-radius:4px;
    box-shadow:0 2px 4px -2px rgb(0 0 0 / 50%);
    max-width:100%;

    [data-input] {
        margin-bottom:10px;

        &:last-of-type {
            margin-bottom:0;
        }
    }

    [data-textarea] {
        margin-bottom:10px;

        &:last-of-type {
            margin-bottom:0;
        }
    }

    [data-select] {
        margin-bottom:10px;

        &:last-of-type {
            margin-bottom:0;
        }

        [data-value] {
            font-size:13px;
        }
    }

    [class="DatePicker "] {
        width:100%;
        z-index:0;
    }
}