/********************* IFRAME FASTVIEW ************************/

header {
    display:none;
}

/********************* FIM IFRAME FASTVIEW ************************/

:root {
    --mobile-size: 500px;
}

html, body {
    padding:0;
    margin:0;
    border:0;
    font-family: 'Roboto', sans-serif;
}

body {
    background:#e5e9ec!important; 
    overflow-y:scroll;
}

p {
    color:#8b91a0;
    font-size:14px;
}

.content {
    padding:30px;
    position:relative;
    left:50px;
    width:calc(100% - 50px);
}

::-webkit-scrollbar {width:7px;height:7px}
::-webkit-scrollbar-track {background: #f1f1f1;}
::-webkit-scrollbar-thumb {background: #97a3b3;}
::-webkit-scrollbar-thumb:hover {background: #6a727c;}
::placeholder {color:#aaa; opacity: 1;}
:-ms-input-placeholder {color: #aaa;}  
::-ms-input-placeholder {color: #aaa;}

@media screen and (max-width:500px){
    .content {
        padding:20px;
        left:initial;
        width:100%;
    }
}

@media print{
    .content {
        left:initial;
        width:100%;
        padding:0;
    }
}

/*BOOTSTRAP CUSTOM*/
.text-primary {
    --bs-text-opacity: 1;
    color: #0090d9!important;
}

.cursor-pointer {
    cursor:pointer;
}

button .spinner-border {
    width: 13px;
    height: 13px;
    border-width: 2px;
}

/*SWIPER*/
.swiper-slide {
    width:auto;
}

/*DATE PICKER*/
.react-datepicker__input-container {
    display:flex;align-items:center;
}

.react-datepicker-popper {
    z-index:1;
}

.react-datepicker-wrapper {
    width:100%;
}

.react-datepicker {
    background:#fff;
    font-size:15px;
    font-weight:400;
    box-shadow:0 2px 4px -2px rgb(0 0 0 / 50%);
    padding:8px 12px;
    border-radius:6px;
    margin-left:-12px;

    .react-datepicker__header_control {
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:100%;
        margin:4px 0;

        button {
            background:transparent;
            outline:none;
            border:none;
            font-weight:600;
            border-radius:4px;
            padding:8px 12px;

            &:hover {
                background:#eee;
            }
        }

        span {
            font-weight:600;
        }
    }

    .react-datepicker__current-month {
        text-align:center;
        font-weight:600;
        margin:10px 0 0;
    }

    .react-datepicker__day-names {
        display:flex;

        >div {
            flex:1;   
            text-align:center; 
            padding:8px 6px;   
            font-weight:600;
        }
    }

    .react-datepicker__week {
        display:flex;
    }

    .react-datepicker__day {
        flex:1;
        text-align:center;
        cursor:pointer;
        padding:8px;   
        border-radius:4px;         
        margin:3px 0;

        &:hover {
            background:#eee;
        }

        &.react-datepicker__day--disabled {
            opacity:.3;
            pointer-events:none;
        }
    }

    .react-datepicker__day--selected {
        background:#0aa699;
        color:#fff;

        &:hover {
            background:#0aa699!important;
        }
    }
}