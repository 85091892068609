.tableCell {
    padding:12px 20px;
    font-size:14px;
    color:#576475;

    svg {
        font-size:16px;
        color:#97a3b3;
        cursor:pointer;
        margin:0 4px;
    }
}

@media print {
    .tableCell:first-child {
        padding-left:0;
    }

    .tableCell:last-child {
        padding-right:0;
    }
}