.box__textarea {
    background:#fff;
    border:1px solid #e5e9ec;
    border-radius:4px;     
    display:flex;
    align-items:center;
    justify-content:space-between;
    transition:background ease .3s;

    &.box__textarea_focus {
        background:#f4f5f7;
    }

    textarea {
        border:none;
        outline:none;
        width:100%;
        height:150px;
        min-height:80px;
        text-align:left;
        padding:8px 12px;
        font-size:13px;
        background:transparent;
    }

    label {
        padding-right:8px;
        font-weight:500;
        font-size:13px;
    }
}