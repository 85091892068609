.tableRow {
    background:#fff;
    border-top-left-radius:6px;
    border-top:1px solid #e8edf1;    
}

thead {
    .tableRow {
        border-top:none;
    }
}

tbody {
    .tableRow {
        &:hover {
            background:#f8f8f8;
        }
    }
}