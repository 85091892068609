.iconContainer {
    display:inline-block;
    position:relative;

    &:before{
        content:'';
        position:absolute;
        top:calc(50% - 16px);
        left:calc(50% - 17px);
        width:34px;
        height:34px;
        background:rgba(0,0,0,0.06);
        border-radius:50%;
        z-index:1;
        transition:transform ease 0s;
        transform:scale(0);
        cursor:pointer;
    }

    &:hover {
        &:before {
            transform:scale(1);
            transition:transform ease .1s;
        }
    }

    svg {
        position:relative;
        color:#97a3b3;
    }
}

@media screen and (max-width:500px){
    .iconContainer {
        svg {
            font-size:24px;
        }
    }
}