.tableCell {
    position:relative;
    padding:16px 20px;
    font-size:13px;
    color:#6f7b8a;
    text-transform:uppercase;
    letter-spacing:0.05em;
    white-space:nowrap;
}

@media print {
    .tableCell:first-child {
        padding-left:0;
    }

    .tableCell:last-child {
        padding-right:0;
    }
}