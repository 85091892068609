.toast {
    position:fixed;
    right:30px;
    bottom:30px;
    z-index:999;
    background:rgba(20,20,20,.97);
    color:#fff;
    font-size:13px;
    font-weight:400;
    border-radius:8px;
    padding:12px 20px;
    min-width:250px;
    opacity:0;
    transition:all ease .3s;
    transform:translateY(100px);
    pointer-events:none;
}

.toast__show {
    opacity:1;
    transform:translateY(0);
    pointer-events:all;
}